.hint {
  position: fixed;
  bottom: 2em;
  left: 2em;
  ul {
    gap: 5px;
    li {
      position: relative;
      i {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
      }
    }
  }
}