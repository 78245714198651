
.auth-form {
  input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    border-top: 1px solid var(--cl-grey);
  }

  > span {
    cursor: pointer;
  }
  .form-header > p {
    padding: 1rem;
    color: var(--cl-text-icon);
    border: 2px solid var(--cl-orange);
    border-radius: 8px;
    font-weight: 500;
  }
}
