.modal {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--cl-grey-opace);
  > * {
    box-shadow: var(--shadow-l);
    // border-radius: 8px;
    background-color: var(--cl-grey);
    max-width: 100vw;
    min-width: 300px;
  }
}

.request-access {
  > h2, > p { color: white;}
  max-width: 500px;
  width: 100%;
  textarea {
    height: 200px;
  }
}