form {
  display: grid;
  // border-radius: 8px;
  overflow: hidden;

  .form-header {
    border-bottom: 2px solid var(--cl-grey-light);
    padding: 1rem;
    display: grid;
    gap: 5px;
    i {
      color: var(--cl-text-icon);
    }
    h3, h2 {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      color: var(--cl-text-icon);
    }
    .form-folder {
      color: var(--cl-text-icon);
    }
  }
  .form-body {
    padding: 0.5em 1em 1em 1em;
    display: grid;
    gap: 10px;
    > button, a {
      justify-self: end;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      border: 2px solid var(--cl-grey-light);
      padding: 0.7rem 2rem;
      gap: 1em;
      border-radius: 8px;
      color: var(--cl-text-icon);
      font-weight: 600;
    }
    label {
      color: var(--cl-text-icon);
      gap: 0.2rem;
      font-weight: 600;
    }
    input {
      border: 2px solid var(--cl-acc-d);
      border-radius: 8px;
    }
  }
  label {
    display: grid;
  }
  &.form-new {
    > .form-body {
      display: grid;
      grid-auto-flow: column;
      padding-top: 1em;
      gap: 1em;
      > div {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        padding: 0.5em;
        border: 1px solid var(--cl-grey-light);
        background-color: var(--cl-grey-light);
        border-radius: 8px;
        padding-right: 2em;
        i {
          padding: 0.5rem;
          font-size: 170%;
        }
        gap: 10px;
        align-items: center;
      }
    }
  }
}

.keywords {
  grid-template-columns: 1fr max-content;
  input {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    z-index: 1;
  }
  button {
    position: relative;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 0 0.5rem;
    background-color: var(--cl-acc-d);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    * {
      color: var(--cl-text-icon);
    }
    &:before {
      content: "";
      position: absolute;
      width: 20px;
      top: 0;
      height: 100%;
      left: -10px;
      background-color: var(--cl-acc-d);
    }
  }
}

.keywords-list {
  display: flex;
  flex-wrap: wrap;
  li {
    padding: 0.3em 1em;
    background-color: var(--cl-grey-light);
    display: grid;
    height: 30px;
    font-size: 80%;
    border-radius: 15px;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 10px;
    i {
      font-size: 120%;
    }
  }
}

.links {
  display: grid;
  > li {
    padding: 0.3em;
    border-bottom: 1px solid var(--cl-grey-light);
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 5px;
    align-items: center;
    max-width: 600px;
  }
}

.form-folder {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  align-items: center;
}

.select-folder {
  overflow: hidden;
  padding: 2em;
  background-color: white;
  border-radius: 8px;
  > .menu_item {
    overflow: hidden;
  }
  h3 {
    padding: 1em;
  }
}

.share {
  gap: 1em;
  .share_link {
    border-radius: 8px;
    border: 2px solid var(--cl-acc-d);
    background-color: var(--cl-grey-light);
    padding: 0.5em 1em;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;
    column-gap: 2em;
    row-gap: 0.5em;
    overflow-wrap: anywhere;
    small {
      grid-column: 1 / 3;
      padding-bottom: 0.5em;
      border-bottom: 1px solid white;
    }
  }
  a {
    justify-self: end;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    border: 2px solid var(--cl-grey-light);
    padding: 0.5em 2em;
    gap: 1em;
    border-radius: 8px;
  }
}

.viewer_modal {
  padding: 2em;
  gap: 1em;
  display: grid;
  place-content: center;
  button {

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 2em;
  }
}

.select-playlist {
  display: grid;
  input {
    background-color: var(--cl-grey-light);
  }
  ul {
    padding: 0.5rem;
    li {
      display: flex;
      align-items: center;

    }
  }

}