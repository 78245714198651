.dashboard-header {
  grid-area: header;
  height: 55px;
  background-color: var(--cl-acc);
  .header_menu {
    width: 50px;
    > i {
      color: white;
    }
  }
  .header_logo {
    width: 250px;
  }
  .header_add {
    width: 100%;
    padding: 0 0.5rem;
    justify-self: start;
    place-self: center;
    > * {
      background-color: white;
    }
    &[data-active="true"] > button {
      background-color: var(--cl-green);
      > i {
        color: white;
      }
    }
    > i:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    > input {
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
    > button {
      border-radius: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
      > i {
        margin-right: 0;
      }
    }
  }
  i {
    padding: 0.5em;
    position: relative;
  }
  .header_profile {
    grid-auto-flow: column;
    i {
        color: white;
    }
  }
}