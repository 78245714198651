.path {
  height: 45px;
  grid-auto-columns: max-content;
  position: sticky;
  top: 0;
  > div {
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    height: 45px;
    > div {
      grid-auto-flow: column;
    }
  }
  button {
    place-self: stretch;
    display: flex;
    align-items: center;
  }
}