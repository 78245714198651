:root {
	--cl-pri: #E91E63;
	--cl-pri-d: #C2185B;
	--cl-pri-l: #F8BBD0;

	--cl-acc:  #9E9E9E;
	--cl-acc-d: #616161;
	--cl-acc-l: #f4f4f4;

	--cl-divider: #BDBDBD;

	--cl-text-pri: #212121;
	--cl-text-sec: #757575;
	--cl-text-icon: #FFFFFF;

	--cl-grey: #9E9E9E;
	--cl-grey-dark: #616161;
	--cl-grey-light: #f4f4f4;
	--cl-selected: #e8e7e7;

	--cl-white-opace: #ffffffeb;
	--cl-grey-opace: #d2d2d2a1;

	--cl-yellow: #FFECB3;
	--cl-red: #f81500;
	--cl-green: #b3d888;
	--cl-blue: #99c6f7;
	--cl-orange: #ff9e10;

	--shadow-xl: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
	--shadow-l: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	--shadow-m: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	--shadow-m-inset: inset 0 10px 20px rgba(0, 0, 0, 0.19), inset 0 6px 6px rgba(0, 0, 0, 0.23);
	--shadow-m-w: 0 10px 20px rgba(255, 255, 255, 0.19), 0 6px 6px rgba(255, 255, 255, 0.23);
	--shadow-s: 0 3px 6px rgba(0,0,0,0.16), 0 0px 4px rgba(0,0,0,0.23);
	--shadow-s-inset: inset 0 3px 6px rgba(0,0,0,0.16), inset 0 0px 4px rgba(0,0,0,0.23);
	--shadow-s-m: 0 3px 6px rgba(255, 255, 255, 0.19), 0 0px 4px rgba(255, 255, 255, 0.19);
	--shadow-xs: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/*
BREAKPOINTS:
XS	<576px
S		≥576px
M 	≥768px
L		≥992px
XL	≥1200px
*/
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

@media screen and (max-width: 800px) {
	html {
		font-size: 80%;
	}
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*  */
.flow-05 > * + * {
  margin-top: 0.5rem;
}
.flow-1 > * + * {
  margin-top: 1rem;
}
.flow-2 > * + * {
  margin-top: 2rem;
}
/* BUTTON */
button {
	background-color: inherit;
	border: none;
	outline: none;
	line-height: 1;
	cursor: pointer;
}
button * {
	cursor: pointer;
}
button:disabled {
	background-color: var(--cl-acc);
}
button:disabled > i {
	color: var(--cl-grey);
	pointer-events: none;
}
.button-icon {
	display: flex;
	align-items: center;
	gap: 1rem;
}

h1, h2, h3, h4, ul, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
	color: inherit;
}

ul {
	list-style-type: none;
}

input {
  padding: 0.8rem 0.5rem;
  border: 1px solid var(--cl-grey-light);
	border-radius: 8px;
	width: 100%;
}
textarea {
	padding: 0.8rem 0.5rem;
  border: 1px solid var(--cl-grey-light);
	border-radius: 8px;
	width: 100%;
}
textarea:focus, input:focus {
	outline: none;
}
select {
	padding: 0 1rem;
	border: none;
	border-bottom: 1px solid var(--cl-acc);
}

/* ICONS */
i {
	color: var(--cl-grey-dark);
	cursor: pointer;
}
.icon-disabled {
	color: var(--cl-grey);
	cursor: not-allowed;
	pointer-events: none;
}

/* STATUS CLASSES */
.selected {
	background-color: var(--cl-grey-light);
}

/* FONT SIZES */
.size-s {
	font-size: small;
}
.size-12 {
	font-size: 1.2rem;
}
.size-14 {
	font-size: 1.4rem;
}
.size-18 {
	font-size: 1.8rem;
}
.size-25 {
	font-size: 2.5rem;
}
/* FONT WEIGHTS */
.weight-500 {
	font-weight: 500;
}
/* FONT COLORS */
.cl-text-pri {
	color: var(--cl-text-pri);
}
.cl-text-sec {
	color: var(--cl-text-sec);
}
.cl-text-icon {
	color: var(--cl-text-icon);
}
/* FONT COLORS CONTENT */
.cl-content-pri * {
	color: var(--cl-text-pri);
}
.cl-content-sec * {
	color: var(--cl-text-sec);
}
.cl-content-icon * {
	color: var(--cl-text-icon);
}
/* TEXT ALIGN */
.text-align-c {
	text-align: center;
}

/* BACKGROUND COLORS */
.bg-pri {
	background-color: var(--cl-pri);
}
.bg-pri-d {
	background-color: var(--cl-pri-d);
}
.bg-pri-l {
	background-color: var(--cl-pri-l);
}
.bg-acc {
	background-color: var(--cl-acc);
}
.bg-acc-d {
	background-color: var(--cl-acc-d);
}
.bg-acc-l {
	background-color: var(--cl-acc-l);
}
.bg-divider {
	background-color: var(--cl-divider);
}
.bg-pri {
	background-color: var(--cl-pri);
}

.bg-white {
	background-color: white;
}
.bg-black {
	background-color: black;
}
.bg-grey {
	background-color: var(--cl-grey);
}
.bg-grey-light {
	background-color: var(--cl-grey-light);
}
.bg-grey-dark {
	background-color: var(--cl-grey-dark);
}
.bg-primary {
	background-color: var(--cl-primary);
}

.rounded {
	border-radius: 8px;
}

.flex-center {
	display: flex;
	align-items: center;
}
/* FLEX */
.flex {
	display: flex;
}

/* GRID */
.grid {
  display: grid;
}
.grid-af-c {
	grid-auto-flow: column;
}
.grid-ac-m {
	grid-auto-columns: max-content;
}
.content-center {
	place-content: center;
}
.justify-i-e {
	justify-items: end;
}
.align-i-c {
	align-items: center;
}
.align-i-e {
	align-items: end;
}
.justify-s {
	justify-self: start;
}
.justify-e {
	justify-self: end;
}
.align-s {
	align-self: start;
}
.align-e {
	align-self: end;
}

.centered-grid {
	display: grid;
  place-items: center;
}
.aligned-grid {
	display: grid;
	align-items: center;
}
.stretched-grid {
	display: grid;
	place-items: stretch;
}

.grid-ac-m {
	grid-auto-columns: max-content;
}
.grid-ac-1 {
	grid-auto-columns: 1fr;
}

.grid-tc-mm {
	grid-template-columns: max-content max-content;
}
.grid-tc-1m {
	grid-template-columns: 1fr max-content;
}
.grid-tc-m1 {
	grid-template-columns: max-content 1fr;
}
.grid-tc-m1m {
  grid-template-columns: max-content 1fr max-content;
}
.grid-tc-mm1 {
	grid-template-columns: max-content max-content 1fr;
}
.grid-tc-1mm {
	grid-template-columns: 1fr max-content max-content;
}
.grid-tc-m11m {
  grid-template-columns: max-content 1fr 1fr max-content;
}
.grid-tc-m1mm {
	grid-template-columns: max-content 1fr max-content max-content;
}
.grid-tc-mm1m {
	grid-template-columns: max-content max-content 1fr max-content;
}
.grid-tr-1m {
	grid-template-rows: 1fr max-content;
}
.grid-tr-m1 {
  grid-template-rows: max-content 1fr;
}
.grid-tr-11m {
	grid-template-rows: 1fr 1fr max-content;
}
.grid-tr-mm1 {
  grid-template-rows: max-content max-content 1fr;
}
.grid-tr-m1mm {
	grid-template-rows: max-content 1fr max-content max-content;
}
.grid-tc-mm11m {
	grid-template-columns: max-content max-content 1fr 1fr max-content;
}
/* GAPS */
.gap-1 {
	gap: 1rem;
}
.gap-12 {
	gap: 1rem 2rem;
}
.gap-24 {
	gap: 2rem 4rem;
}
.gap-02 {
	gap: 0 2rem;
}
.gap-xs {
	gap: 0.1rem;
}
.gap-s {
  gap: 0.5rem;
}
.c-gap-s {
	column-gap: 0.5rem;
}
.gap-m {
	gap: 1rem;
}
.c-gap-m {
	column-gap: 1rem;
}
.cgap-m {
	column-gap: 1rem;
}
.gap-l {
	gap: 2rem;
}
.rgap-s {
	row-gap: 0.5rem;
}

.overflow-h {
  overflow: hidden;
}
.overflow-a {
	overflow: auto;
}

.full-screen {
  height: 100%;
  width: 100vw;
}

/* SHADOWS */
.shadow-s {
	box-shadow: var(--shadow-s);
}
.shadow-m {
	box-shadow: var(--shadow-m);
}
.shadow-l {
	box-shadow: var(--shadow-l);
}
.shadow-xl {
	box-shadow: var(--shadow-xl);
}
/* DROP SHADOWS */
.drop-shadow-d {
	filter: drop-shadow(0 0 0.2rem var(--cl-acc-d));
}

.pd-05 {
	padding: 0.5rem
}
.pd-1 {
	padding: 1rem;
}
.pd-2 {
	padding: 2rem;
}
.pd-21 {
	padding: 2rem 1rem;
}
.pd-4 {
	padding: 4rem;
}
.pd-24 {
	padding: 2rem 4rem;
}
.pd-050 {
	padding: 0.5rem 0;
}
.pd-051 {
	padding: 0.5rem 1rem;
}
.pd-0515 {
	padding: 0.5rem 1.5rem;
}
.pd-01 {
	padding: 0 1rem;
}
.pd-1112 {
	padding: 1rem 1rem 1rem 2rem;
}
.pd-005 {
	padding: 0 0.5rem;
}
.pd-01501 {
	padding: 0 1.5rem 0 1rem;
}
.pd-0505051 {
	padding: 0.5rem 0.5rem 0.5rem 1rem;
}
.pd-051051 {
	padding: 0.5rem 1rem;
}
.pd-1010 {
	padding: 1rem 0;
}
.pd-1311 {
	padding: 1rem;
	padding-right: 3rem;
}
.pd-0020 {
	padding-bottom: 2rem;
}
.z1 {
	z-index: 1;
}
.z2 {
	z-index: 2;
}

.opacity-0 {
	opacity: 0;
}

.error {
	font-size: small;
	color: var(--cl-red);
	font-weight: 400;
	text-align: center;
}

/* Flex utility classes */
.row-1m {
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
}
.row > * + * {
	padding-right: 0.5rem;
}

.sequence-icon {
	transform: rotate(45deg);
	position: relative;
}

.public-content {
	max-width: 992px;
	margin: 0 auto;
	width: 100%;
}

i[data-icon-active="true"] {
	color: var(--cl-orange);
}