.menu {
  width: 0;
  grid-template-rows: 45px repeat(3, max-content);
  overflow-y: auto;
  position: relative;
  > * { background-color: white;}
  > .selected > div > i { color: var(--cl-orange);}
  h3 {
    align-self: center;
    height: 45px;
    position: sticky;
    top: 0;
    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      height: 2px;
      background-color: var(--cl-grey-light);
    }
  }
  &.open {
    width: 300px;
  }

  .menu_item {
    &.selected > .menu_dir_header {
      background-color: var(--cl-selected);
    }
    .menu_item_header {
      height: 45px;
    }
    .folder {
      .folder_header {
        padding: 0.5em;
        padding-left: 1em;
        > i:first-of-type {
          transform: rotate(90deg);
          &.no_child_folder {
            pointer-events: none;
          }
        }
      }
      &.selected {
        > .folder_header {
          background-color: var(--cl-grey-light);
          position: relative;

          &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: var(--cl-grey-light);
            top: 0;
            transform: translateX(-100%);
            z-index: 1;
          }
        }
      }
      .folder_folders {
        position: relative;
        padding-left: 1rem;
        &:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: white;
          top: 0;
          transform: translateX(-100%);
        }
      }
      &.open > .folder_header > i:first-of-type {
        transform: rotate(-90deg);
      }
    }
  }
}
