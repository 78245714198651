.loading-spinner {
  position: relative;
  margin: 0 auto;
  width: 100px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke: var(--cl-acc-d);
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}

.loading-bar, .loading-bar:before {
  height: 3px;
  width: 100%;
  margin: 0;
}
.loading-bar {
  background-color: var(--cl-acc);
  display: -webkit-flex;
  display: flex;
  &:before {
    background-color: var(--cl-acc-d);
    content: '';
    -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}
@keyframes running-progress {
  0% { margin-left: 0px; margin-right: 100%; }
  65% { margin-left: 25%; margin-right: 0%; }
  100% { margin-left: 100%; margin-right: 0; }
}